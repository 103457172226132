@use "shared";
@use "../utilities/initial-variables" as iv;

%checkbox-radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative;

  input {
    cursor: pointer;
  }

  &[disabled],
  fieldset[disabled] &,
  input[disabled] {
    color: shared.$input-disabled-color;
    cursor: not-allowed;
  }
}

.#{iv.$class-prefix}checkbox {
  @extend %checkbox-radio;
}

.#{iv.$class-prefix}radio {
  @extend %checkbox-radio;

  & + .#{iv.$class-prefix}radio {
    margin-inline-start: 0.5em;
  }
}
