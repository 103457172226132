:root {
  --bulma-primary-h: 192;
  --bulma-primary-s: 60%;

  --bulma-link-h: 42;
  --bulma-link-s: 80%;
  --bulma-link-on-scheme-l: 70%;

  --bulma-family-secondary: "source-serif-4", Georgia, "Times New Roman", Times, serif;
}

#portfolio-case .content,
#post .content {
  --bulma-content-block-margin-bottom: 2em;
}

[data-theme="light"],
.theme-light {
  --bulma-primary-s: 50%;

  --bulma-link-h: 192;
  --bulma-link-on-scheme-l: 30%;
  --bulma-link-l: 30%;
  --bulma-link-s: 100%;
}

.title,
.subtitle {
  --bulma-title-weight: var(--bulma-weight-light);
}

.content {
  --bulma-content-heading-weight: var(--bulma-weight-normal);
}

h1,
h2,
h3,
h4 {
  font-family: var(--bulma-family-secondary);
}

// Disable the widescreen breakpoint
$widescreen-enabled: false;
// Disable the fullhd breakpoint
$fullhd-enabled: false;
