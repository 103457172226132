.text-mono {
  font-family: "SFMono-Regular", SFMono-Regular, Consolas, "Liberation Mono", Menlo, Monaco, "Courier New", Courier, monospace;
}

video {
  border-radius: 8px;
}

.navbar-item.active {
  box-shadow: 0 -2px 0 inset var(--bulma-primary);
}

@media screen and (max-width: 1023px) {
  .navbar-item.active {
    box-shadow: 2px 0 0 inset var(--bulma-primary);
  }
}

[data-theme="light"] {
  .navbar {
    border-bottom: 1px solid var(--bulma-text-95);
  }
}

[data-theme="dark"] {
  .navbar {
    background-color: var(--bulma-text-00);
    border-bottom: 1px solid var(--bulma-text-10);
  }
}

#theme-icon {
  cursor: pointer;
}

#portfolio-case .content,
#post .content {
  h2 {
    margin-bottom: 1em;
  }

  h2:not(:first-of-type) {
    margin-top: 3em;
  }
}

#toc {
  position: -webkit-sticky;
  position: sticky;
  top: 96px;
  height: calc(100vh - 96px);
  overflow-y: auto;

  ul {
    border-left: 2px solid var(--bulma-link-soft);
  }

  ul li {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 -2px;

    a {
      padding: 0.5rem 0.75rem;
      border-left: 2px solid transparent;
      display: block;

      &:hover:not(.active) {
        border-left-color: var(--bulma-link);
        color: var(--bulma-link-bold);
      }

      &.active {
        border-left: 2px solid var(--bulma-link);
        background-color: var(--bulma-link-soft);
        color: var(--bulma-link-bold);
      }
    }

    &.toc-h3 a {
      padding-left: 1.25rem;
    }
    &.toc-h4 a {
      padding-left: 1.75rem;
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Initial state of the body with opacity 0 */
body {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Class to be added on page load for fade in */
.fade-in {
  opacity: 1 !important;
}

.is-horizontal {
  display: flex;
  flex-basis: 50ex;
  flex-grow: 0;
  flex-shrink: 1;
}

.is-horizontal .card-image {
  flex: 1.25;
  flex-shrink: 1;
}

.is-horizontal .card-image .image {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.is-horizontal .card-image .image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.is-horizontal .card-content {
  flex: 3;
}

.is-horizontal .card-content .content {
  flex: 1;
}

@media (min-width: 768px) {
  .is-horizontal .card-image .image img {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0;
  }
}

@media (max-width: 768px) {
  .is-horizontal {
    flex-direction: column;
  }
}

/* GALLERY */

.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery .item {
  flex-grow: 1;
  height: 80px;
  max-width: 120px;
  min-width: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 4px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
}

.gallery img,
.tobii-zoom__icon {
  display: none;
}

// HERO

.hero {
  position: relative;
  overflow: hidden;
}

#artCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: luminosity;
  opacity: 0.1;
  z-index: -1;
}

[data-theme="light"] {
  #artCanvas {
    opacity: 0.15;
  }
}

.hero-base-color {
  position: absolute;
  left: 0;
  top: 0;
  right: 0px;
  bottom: 0px;
  z-index: -2;
}

@media screen and (min-width: 769px), print {
  #home {
    .hero-body {
      background-repeat: no-repeat;
      background-position: calc(50% + 200px) bottom;
      text-shadow: 0 0 3px hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-background-l));
    }
  }
  #home.light-theme {
    .hero-body {
      background-image: url("/assets/images/jpfaraco_dithered-light.gif");
    }
  }
  #home.dark-theme {
    .hero-body {
      background-image: url("/assets/images/jpfaraco_dithered-dark.gif");
    }
  }
}

// Portfolio case study

#portfolio-case {
  #case-intro {
    .content ul {
      list-style: none;
      margin: 0;
    }
  }
}

// Before-after comparison

.image-compare {
  width: 100%;
  padding-top: 60.504202%; /* Sets the height as a percentage of the width */
  position: relative;
  user-select: none;
  overflow: hidden;
  margin-bottom: var(--bulma-content-block-margin-bottom);

  &:hover {
    .image-compare-label {
      opacity: 1;
    }
  }

  * {
    box-sizing: border-box;
  }

  .image-compare-label {
    background-color: #838383b0;
    color: white;
    z-index: 1;
    position: absolute;
    bottom: 20px;
    padding: 5px 15px;
    border: 1px solid #ffffff63;
    opacity: 0;
    transition: 0.5s all;
    display: block;
    font-family: sans-serif;
    font-size: 14px;
  }

  .image-compare-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;

    .image-compare-image {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      max-width: none;
    }

    &:first-of-type {
      width: 50%;
      z-index: 2;

      .image-compare-label {
        left: 20px;
      }
    }

    &:last-of-type {
      .image-compare-label {
        right: 20px;
      }
    }
  }

  .image-compare-handle {
    position: absolute;
    top: 0;
    left: calc(50% - 2.5px);
    background-color: white;
    width: 5px;
    height: 100%;
    z-index: 2;
    transition: 0.4s opacity;
    pointer-events: none;

    &:hover {
      opacity: 0.6;
    }

    &:before {
      pointer-events: all;
      content: "❮❯";
      position: absolute;
      cursor: pointer;
      background-color: white;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      color: black;
      font-weight: 700;
    }
  }
}
