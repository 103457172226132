@use "../utilities/css-variables" as cv;
@use "../utilities/initial-variables" as iv;
@use "../utilities/mixins" as mx;

$section-padding: 3rem 1.5rem !default;
$section-padding-desktop: 3rem 3rem !default;
$section-padding-medium: 9rem 4.5rem !default;
$section-padding-large: 18rem 6rem !default;

.#{iv.$class-prefix}section {
  @include cv.register-vars(
    (
      "section-padding": #{$section-padding},
      "section-padding-desktop": #{$section-padding-desktop},
      "section-padding-medium": #{$section-padding-medium},
      "section-padding-large": #{$section-padding-large},
    )
  );
  padding: cv.getVar("section-padding");

  // Responsiveness
  @include mx.desktop {
    padding: cv.getVar("section-padding-desktop");

    // Sizes
    &.#{iv.$class-prefix}is-medium {
      padding: cv.getVar("section-padding-medium");
    }

    &.#{iv.$class-prefix}is-large {
      padding: cv.getVar("section-padding-large");
    }
  }
}
